import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { marked } from 'marked';
import Moment from 'react-moment';
import AvatarPink from '../img/avatarPink.png';
import { StateContext } from './StateContainer';
import Loading from './Loading'; // Importa il componente di caricamento
import { FaRegThumbsUp as ThumbUp } from 'react-icons/fa';
import { FaRegThumbsDown as ThumbDown } from 'react-icons/fa';

const MacchinaDaScrivere = ({
  text,
  row,
  primaryRGBA,
  ControlsBeforeSendingMessage,
  setIsUserScrolling,
  setLoading,
  message,
  userLastMessageRef,
  setMessage,
  setTotalChat,
  conversationId,
  setLeadCode,
}) => {
  const {
    dettagliProgetto,
    setIsTypingMachine,
    setBotMessageSent,
    conversationStarted,
    setConversationStarted,
    setTypedTextScroll,
    stopAgent,
    showLoading,
    setShowLoading,
  } = useContext(StateContext);

  console.log(dettagliProgetto);

  let textLines = text.split(/\n|<br>/).filter((el) => el.trim().length > 0);
  const [typedText, setTypedText] = useState(Array(textLines.length).fill(''));
  const [selectActionValue, setSelectActionValue] = useState('');
  const [dateTimeActionValue, setDateTimeActionValue] = useState('');
  const [currentLine, setCurrentLine] = useState(0);
  const [currentChar, setCurrentChar] = useState(0);
  const [isTyping, setIsTyping] = useState(Array(textLines.length).fill(true)); // Stato per ogni riga
  const [showFadeIn, setShowFadeIn] = useState(
    Array(textLines.length).fill(false)
  ); // Stato per gestire l'animazione fade-in
  const [feedbackInteractionNotes, setFeedbackInteractionNotes] = useState('');

  const InteractionFeedbackRequest = (
    feedbackInteractionValue,
    interactionCode
  ) => {
    setBotMessageSent(true);
    setIsUserScrolling(false);
    setLoading(true);

    const conversation = {
      userMessage: {
        message:
          feedbackInteractionValue === 'thumb-up'
            ? 'Questa risposta mi è piaciuta'
            : 'Questa risposta non mi è piaciuta',
        date: new Date(),
      },
      botMessage: {
        text: ``,
        sources: '',
        interactionCode: '',
        date: new Date(),
        split: false,
        faq_answer: false,
        faq_actions: [],
        show_thumbs: true,
      },
    };
    setTotalChat((prevTotal) => [...prevTotal, conversation]);

    const apiURL = `${process.env.REACT_APP_PYTHON_ENGINE}/feedback-interaction`;

    const requestOptions = {
      method: 'POST', // Puoi impostare il metodo come 'GET' o 'POST' in base alle tue esigenze
      headers: {
        'Content-Type': 'application/json',
        // "Cache-Control": "no-cache",
      },
      body: JSON.stringify({
        message:
          feedbackInteractionValue === 'thumb-up'
            ? 'Questa risposta mi è piaciuta'
            : 'Questa risposta non mi è piaciuta',
        sessionId: conversationId,
        interactionCode: interactionCode,
        projectCode: dettagliProgetto.code,
        feedbackInteractionValue: feedbackInteractionValue,
        feedbackInteractionNotes: feedbackInteractionNotes,
      }),
    };

    fetch(apiURL, requestOptions)
      .then((response) => {
        setBotMessageSent(false);
        const closeButton = document.getElementById(
          'close-button-modal-note-' + interactionCode
        );
        if (closeButton) {
          closeButton.click();
        }
        setFeedbackInteractionNotes('');
        setLoading(false);
        if (!response.ok) {
          throw new Error('Errore durante la richiesta.');
        }

        conversation.botMessage.text = '';

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        const readChunk = () => {
          let chunk = '';
          return reader.read().then(({ done, value }) => {
            if (done) {
              return;
            }

            let response = decoder
              .decode(value, { stream: true })
              .replace(/}{/g, '},{');

            let valueOfResponse = JSON.parse(`[${response}]`);

            if (valueOfResponse[0].faq_answer) {
              valueOfResponse.forEach((value) => {
                chunk += value.answer;
              });
              conversation.botMessage.faq_answer = true;
              if (valueOfResponse[0].actions) {
                conversation.botMessage.faq_actions.push(
                  valueOfResponse[0].actions
                );
              }
              if (valueOfResponse[0].interactionCode) {
                conversation.botMessage.interactionCode =
                  valueOfResponse[0].interactionCode;
              }
              if ('show_thumbs' in valueOfResponse[0]) {
                let show_thumbs = valueOfResponse[0].show_thumbs;
                conversation.botMessage.show_thumbs = show_thumbs;
              }
            } else {
              valueOfResponse.forEach((value) => {
                chunk += value.answer;
              });
            }

            // Controlla se il chunk contiene [sources] e [/source]
            conversation.botMessage.faq_answer += false;
            const startTag = '[sources]';
            const endTag = '[/sources]';
            const startIndex = chunk.indexOf(startTag);
            const endIndex = chunk.indexOf(endTag);

            conversation.botMessage.split += true;

            if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
              // Il chunk contiene [sources] e [/source], aggiungi il testo tra di essi a conversation.botMessage.sources
              const contentStartIndex = startIndex + startTag.length;
              const contentEndIndex = endIndex;
              const sourcesText = chunk.substring(
                contentStartIndex,
                contentEndIndex
              );
              conversation.botMessage.sources += sourcesText;
            } else {
              // Il chunk non contiene [sources] e [/source], aggiungi il chunk intero a conversation.botMessage.text
              conversation.botMessage.text += chunk;
            }
            setTotalChat((prevTotal) => [...prevTotal]); // Aggiorna lo stato di totalChat

            setTimeout(() => {
              readChunk();
            }, 100); // 2000 millisecondi (2 secondi)
          });
        };

        return readChunk();
      })
      .then(() => {
        setLeadCode('');
        setBotMessageSent(true);
      })
      .catch((error) => {
        console.error('Errore durante la richiesta:', error);
        conversation.botMessage.text =
          'Si è verificato un errore durante la richiesta.' + error;
        setTotalChat((prevTotal) => [...prevTotal]);
      });
  };

  useEffect(() => {
    if (currentLine < textLines.length) {
      setShowFadeIn((prev) => {
        const updatedFadeIn = [...prev];
        updatedFadeIn[currentLine] = true; // Abilita l'animazione per il baloon corrente
        return updatedFadeIn;
      });

      const timeout = setTimeout(() => {
        setShowFadeIn((prev) => {
          const updatedFadeIn = [...prev];
          updatedFadeIn[currentLine] = false; // Disabilita l'animazione
          return updatedFadeIn;
        });
      }, 500); // Durata dell'animazione (0.5s)

      return () => clearTimeout(timeout);
    }
  }, [currentLine, textLines.length]);

  const [messageWritten, setMessageWritten] = useState(
    Array(textLines.length).fill(false)
  ); // Stato per tracciare se una riga è già stata scritta

  useEffect(() => {
    if (stopAgent) {
      var faqActions = document.querySelectorAll('.faq-actions');
      faqActions.forEach(function (element, index) {
        if (!element.classList.contains('d-none')) {
          element.classList.remove('hideElement');
          element.classList.add('animationDoneActionsFaq');
        }
      });

      // Animazione Thumbs
      var thumbsActions = document.querySelectorAll('.thumbs-actions');
      thumbsActions.forEach(function (element, index) {
        if (!element.classList.contains('d-none')) {
          element.classList.remove('hideElement');
          element.classList.add('animationDoneActionsFaq');
        }
      });

      // setConversationStarted(false);
      // Se stopAgent è attivo, fermiamo completamente la scrittura
      setIsTyping((prev) => {
        const updatedIsTyping = [...prev];
        updatedIsTyping[currentLine] = false; // Impostiamo la riga corrente come "non in scrittura"
        return updatedIsTyping;
      });

      // Segna il messaggio corrente come "completato"
      setMessageWritten((prev) => {
        const updatedWritten = [...prev];
        updatedWritten[currentLine] = true; // Indica che il messaggio per questa riga è stato fermato
        return updatedWritten;
      });

      setBotMessageSent(true);
      return; // Uscire dall'effetto per fermare la scrittura
    }

    if (currentLine < textLines.length) {
      if (messageWritten[currentLine]) {
        // Se il messaggio per questa riga è già stato stoppato, non continuiamo a scriverlo
        return;
      }

      const line = textLines[currentLine];
      if (currentChar < line.length) {
        const timeout = setTimeout(() => {
          setTypedText((prev) => {
            const updatedLines = [...prev];
            updatedLines[currentLine] += line[currentChar];
            return updatedLines;
          });

          setTypedTextScroll((prev) => {
            const updatedLines = [...prev];
            updatedLines[currentLine] += line[currentChar];
            return updatedLines;
          });

          setIsTypingMachine(currentChar);
          setCurrentChar((prev) => prev + 1);
        }, 20); // Velocità di scrittura (20ms per carattere)

        return () => clearTimeout(timeout);
      } else {
        // Quando la riga corrente è completata, fermiamo la scrittura
        setIsTyping((prev) => {
          const updatedIsTyping = [...prev];
          updatedIsTyping[currentLine] = false;
          return updatedIsTyping;
        });

        // Segniamo la riga come completata
        setMessageWritten((prev) => {
          const updatedWritten = [...prev];
          updatedWritten[currentLine] = true;
          return updatedWritten;
        });

        if (textLines[textLines.length - 1].length === currentChar) {
          setBotMessageSent(true);
          setConversationStarted(false);
          var faqActions = document.querySelectorAll('.faq-actions');
          faqActions.forEach(function (element, index) {
            if (!element.classList.contains('d-none')) {
              element.classList.remove('hideElement');
              element.classList.add('animationDoneActionsFaq');
            }
          });

          // Animazione Thumbs
          var thumbsActions = document.querySelectorAll('.thumbs-actions');
          thumbsActions.forEach(function (element, index) {
            if (!element.classList.contains('d-none')) {
              element.classList.remove('hideElement');
              element.classList.add('animationDoneActionsFaq');
            }
          });
        }
        setCurrentChar(0); // Reset del carattere corrente
        setCurrentLine((prev) => prev + 1); // Passa alla riga successiva
      }
    } else {
      // Quando tutte le righe sono state completate, fermiamo il processo
      if (textLines[textLines.length - 1].length === currentChar) {
        setBotMessageSent(true);
        setConversationStarted(false);
        var faqActions = document.querySelectorAll('.faq-actions');
        faqActions.forEach(function (element, index) {
          if (element.classList.contains('hideElement')) {
            element.classList.remove('hideElement');
            element.classList.add('animationDoneActionsFaq');
          }
        });

        // Animazione Thumbs
        var thumbsActions = document.querySelectorAll('.thumbs-actions');
        thumbsActions.forEach(function (element, index) {
          if (!element.classList.contains('d-none')) {
            element.classList.remove('hideElement');
            element.classList.add('animationDoneActionsFaq');
          }
        });
      }
      setCurrentChar(0);
    }
  }, [currentChar, currentLine, textLines, messageWritten]); // Aggiungi messageWritten come dipendenza

  useEffect(() => {
    // Mostra il caricamento solo se ci sono più righe da scrivere
    if (currentLine < textLines.length - 1) {
      setShowLoading(true); // Mostra il loading
    } else {
      setShowLoading(false); // Nasconde il loading quando non ci sono più righe da scrivere
    }
  }, [currentLine, textLines.length]);

  const addTargetToLinks = (text) => {
    const linkRegex = /(https?:\/\/[^\s]+)/g;
    const mailRegex = /(\S+@\S+\.\S+)/g;

    text = text.replace(linkRegex, (match) => {
      return (
        <a href="${match}" target="_blank">
          ${match}
        </a>
      );
    });

    text = text.replace(mailRegex, (match) => {
      return (
        <a href="mailto:${match}" target="_blank">
          ${match}
        </a>
      );
    });

    return text;
  };
  const HideTipsQuestions = (
    messaggio,
    actions,
    index,
    widget_clicked = false
  ) => {
    // Seleziona solo le FAQ actions relative al messaggio corrente
    var faqActions = document.querySelectorAll(
      `.faq-actions-container-${row.botMessage.interactionCode} .faq-actions`
    );

    faqActions.forEach(function (element) {
      element.classList.remove('animationDoneActionsFaq');
      element.classList.add('d-none');
    });

    ControlsBeforeSendingMessage(messaggio, widget_clicked);
  };

  const HideTipsQuestionsByLink = () => {
    // Nascondi solo le FAQ actions relative all'interactionCode corrente
    var faqActions = document.querySelectorAll(
      `.faq-actions-container-${row.botMessage.interactionCode} .faq-actions`
    );

    faqActions.forEach(function (element) {
      element.classList.remove('animationDoneActionsFaq');
      element.classList.add('d-none');
    });
  };

  return (
    <>
      <li className="pb-4 message-bot bg-orange">
        <div className="d-flex align-items-start gap-2">
          {/* Immagine */}
          <div
            style={{
              height: '2.4vw',
              width: '2.4vw',
              minHeight: '16px',
              minWidth: '16px',
              borderRadius: '100%',
              background: `url(${
                dettagliProgetto && dettagliProgetto.chatbotIcon
                  ? dettagliProgetto.chatbotIcon
                  : AvatarPink
              }) center center / cover no-repeat`,
            }}
          ></div>

          {/* Contenuto a destra dell'immagine */}
          <div className="d-flex flex-column">
            {/* Titolo */}
            <h5 className="font-size-14 conversation-name m-0">
              <a href="#" className="text-dark text-decoration-none">
                {dettagliProgetto ? dettagliProgetto.assistantName : ''}
              </a>
            </h5>

            {/* Messaggi */}
            {/* Messaggi */}
            {textLines.map((line, index) => (
              <div
                className={`conversation-list m-0 botResponses ${
                  showFadeIn[index] ? 'fade-in' : ''
                } ${typedText[index].length === 0 ? 'd-none' : ''}`}
                style={{ maxWidth: '400px' }}
                key={index}
              >
                <div className="ctext-wrap mt-2 m-0">
                  <div
                    className="ctext-wrap-content m-0 text-dark mb-1"
                    style={{
                      background: `${primaryRGBA}`,
                      maxWidth: '300px',
                      minWidth: '300px',
                      hyphens: 'auto',
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: marked(typedText[index]).replace(
                          /<a /g,
                          "<a target='_blank' "
                        ),
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            ))}

            {/* Baloon di caricamento alla fine */}
            {currentLine < textLines.length - 1 && (
              <div className="conversation-list m-0 botResponses fade-in">
                <Loading />
              </div>
            )}

            <div className="d-flex align-items-end justify-content-between gap-2 font-size-12 text-muted mt-0 ">
              <Moment format="HH:mm">{row.botMessage.date}</Moment>
              {/* Bottoni thumbs */}
              <div
                className={`d-flex align-items-center gap-3 mt-0 ${
                  row.botMessage.show_thumbs === false ? 'd-none' : ''
                }`}
              >
                <button
                  className="btn btn-light p-0 m-0 button-thumb-up bg-transparent font-size-12 thumbs-actions hideElement"
                  onClick={() =>
                    InteractionFeedbackRequest(
                      'thumb-up',
                      row.botMessage.interactionCode
                    )
                  }
                >
                  <ThumbUp />
                </button>

                <button
                  className="btn bg-transparent p-0 m-0 thumbs-actions button-thumb-down font-size-12 hideElement"
                  data-bs-toggle="modal"
                  data-bs-target={`#modal-invia-feedback-${row.botMessage.interactionCode}`}
                  // onClick={() =>
                  //   InteractionFeedbackRequest(
                  //     'thumb-down',
                  //     row.botMessage.interactionCode
                  //   )
                  // }
                >
                  <ThumbDown />
                </button>
              </div>
            </div>

            <div
              // style={{ marginLeft: "24px" }}
              className={`w-100 d-flex justify-content-start gap-1 faq-actions-container-${row.botMessage.interactionCode}`}
            >
              {row.botMessage.faq_actions &&
                row.botMessage.faq_actions[0] &&
                row.botMessage.faq_actions[0].length > 0 &&
                row.botMessage.faq_actions[0].map((action, indexAction) => {
                  return action.type === 'link' ? (
                    <a
                      href={action.content}
                      target="_blank"
                      className="btn bg-transparent mt-2 mb-1 font-size-14 btn-tips-questions faq-actions hideElement"
                      style={{
                        minWidth: '180px',
                        border: `1px ${dettagliProgetto.primaryColor} solid`,
                        color: `${dettagliProgetto.primaryColor}`,
                      }}
                      onClick={HideTipsQuestionsByLink}
                    >
                      {action.etichetta}
                    </a>
                  ) : action.type === 'datetime' ? (
                    <>
                      <input
                        type="datetime-local"
                        className="btn mt-2 mb-1 font-size-14 faq-actions btn-tips-questions px-2 hideElement"
                        style={{
                          border: `1px ${dettagliProgetto.primaryColor} solid`,
                          color: `${dettagliProgetto.primaryColor}`,
                        }}
                        onChange={(event) => {
                          const inputValue = event.target.value; // Ottieni il valore di input
                          const [date, time] = inputValue.split('T'); // Divide data e orario
                          const [year, month, day] = date.split('-'); // Estrae anno, mese e giorno
                          const formattedDate = `${day}-${month}-${
                            parseInt(year) + 1
                          }`; // Formatta la data e aggiungi 1 anno
                          const formattedValue = `${formattedDate} alle ${time}`; // Combina con l'orario
                          setDateTimeActionValue(formattedValue);
                        }}
                      />
                      <button
                        className="btn mt-2 mb-1 font-size-14 faq-actions btn-tips-questions px-2 hideElement"
                        style={{
                          // minWidth: "180px",
                          border: `1px ${dettagliProgetto.primaryColor} solid`,
                          color: `${dettagliProgetto.primaryColor}`,
                        }}
                        onClick={() =>
                          HideTipsQuestions(
                            dateTimeActionValue,
                            row.botMessage.faq_actions[0],
                            indexAction,
                            true
                          )
                        }
                      >
                        Conferma
                      </button>
                    </>
                  ) : action.type === 'select' ? (
                    <>
                      <select
                        className="form-select mt-2 mb-1 font-size-14 faq-actions btn-tips-questions px-2 hideElement"
                        style={{
                          border: `1px ${dettagliProgetto.primaryColor} solid`,
                          color: `${dettagliProgetto.primaryColor}`,
                        }}
                        onChange={(event) => {
                          setSelectActionValue(event.target.value);
                        }}
                      >
                        <option value="" disabled selected>
                          Seleziona un'opzione
                        </option>
                        {row.botMessage.faq_actions[0] &&
                        row.botMessage.faq_actions[0][0] &&
                        row.botMessage.faq_actions[0][0].choices
                          ? row.botMessage.faq_actions[0][0].choices.map(
                              (choice, index) => (
                                <option key={index} value={choice}>
                                  {choice}
                                </option>
                              )
                            )
                          : ''}
                      </select>
                      <button
                        className="btn mt-2 mb-1 font-size-14 faq-actions btn-tips-questions px-2 hideElement"
                        style={{
                          // minWidth: "180px",
                          border: `1px ${dettagliProgetto.primaryColor} solid`,
                          color: `${dettagliProgetto.primaryColor}`,
                        }}
                        onClick={() =>
                          HideTipsQuestions(
                            selectActionValue,
                            row.botMessage.faq_actions[0],
                            indexAction,
                            true
                          )
                        }
                      >
                        Conferma
                      </button>
                    </>
                  ) : (
                    <button
                      className="btn mt-2 mb-1 font-size-14 faq-actions btn-tips-questions px-2 hideElement"
                      style={{
                        // minWidth: "180px",
                        border: `1px ${dettagliProgetto.primaryColor} solid`,
                        color: `${dettagliProgetto.primaryColor}`,
                      }}
                      onClick={() =>
                        HideTipsQuestions(
                          action.content,
                          row.botMessage.faq_actions[0],
                          indexAction
                        )
                      }
                    >
                      {action.etichetta}
                    </button>
                  );
                })}
            </div>
          </div>
        </div>
      </li>

      {/* MODALE */}
      <div
        className="modal fade overlay"
        id={`modal-invia-feedback-${row.botMessage.interactionCode}`}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="false"
        style={{ zIndex: '99999999999999999999999999999999999' }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 py-4">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id={`close-button-modal-note-${row.botMessage.interactionCode}`}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-outline">
                <label className="form-label" htmlFor="formControlLgcvv">
                  Note (facoltativo)
                </label>
                <textarea
                  style={{ minHeight: '250px' }}
                  id="formControlLgcvv"
                  className="form-control form-control-lg fs-6"
                  placeholder="Inserisci delle specifiche su come potrebbe essere migliorata la risposta"
                  value={feedbackInteractionNotes}
                  onChange={(e) => setFeedbackInteractionNotes(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Annulla
              </button>
              <button
                type="button"
                className="btn btn-dark text-white"
                id="creaProgettoButton"
                onClick={() =>
                  InteractionFeedbackRequest(
                    'thumb-down',
                    row.botMessage.interactionCode
                  )
                }
              >
                Invia feedback
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* FINE MODALE  */}
    </>
  );
};

export default MacchinaDaScrivere;
