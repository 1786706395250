import React from 'react';
import Moment from 'react-moment';
import { useContext } from 'react';
import { StateContext } from '../StateContainer';
import AvatarPink from '../../img/avatarPink.png';

const PrimoMessaggioBot = ({ totalChat, primaryRGBA }) => {
  const { dettagliProgetto, setDettagliProgetto, caricamento } =
    useContext(StateContext);
  return (
    <li className="pb-2" id="primoMessaggio">
      <div className="d-flex align-items-start gap-2">
        <div
          style={{
            height: '2.4vw',
            width: '2.4vw',
            minHeight: '16px',
            minWidth: '16px',
            borderRadius: '100%',
            background: `url(${
              dettagliProgetto && dettagliProgetto.chatbotIcon
                ? dettagliProgetto.chatbotIcon
                : AvatarPink
            }) center center / cover no-repeat`,
          }}
          className=""
        ></div>
        <div>
          <h5 className="font-size-14 conversation-name">
            <a
              href="#"
              className="text-dark text-decoration-none"
              style={{
                color: `${
                  dettagliProgetto ? dettagliProgetto.userMessageColor : ''
                }`,
              }}
            >
              {dettagliProgetto ? dettagliProgetto.assistantName : ''}
            </a>{' '}
          </h5>

          <div className="conversation-list" style={{ maxWidth: '400px' }}>
            {dettagliProgetto && dettagliProgetto.initialMessage
              ? dettagliProgetto.initialMessage
                  .split('\n')
                  .map((paragraph, index) => (
                    <div className="ctext-wrap mt-2 mb-0" key={index}>
                      <div
                        className="ctext-wrap-content m-0"
                        style={{
                          background: `${primaryRGBA}`,
                          maxWidth: '300px',
                        }}
                      >
                        <p
                          className="mb-0"
                          style={{
                            color: dettagliProgetto
                              ? dettagliProgetto.userMessageColor
                              : '',
                          }}
                        >
                          {paragraph}
                        </p>
                      </div>
                    </div>
                  ))
              : ''}
            <span className="d-inline-block font-size-12 text-muted mt-1">
              <Moment format="HH:mm">
                {dettagliProgetto && totalChat && totalChat[0]
                  ? totalChat[0].userMessage.date
                  : new Date()}
              </Moment>
            </span>
          </div>
        </div>
      </div>
    </li>
  );
};

export default PrimoMessaggioBot;
