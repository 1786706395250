import React from "react";
import { useEffect, useRef } from "react";
// import ReDesiderio from "../img/ReDesiderio.png";

const VideoAssistente = () => {
 

  return (
    <div id='content'>
      <div id='video-wrapper'>
        <div>
          <video
            id='video-element'
            autoPlay
            src='https://app.tailorgpt.ai/demo/brescia-musei/ReDesiderio_idle.mp4'
            loop
            muted='muted'
          ></video>
        </div>
      </div>

      {/* <br />

      <div id='buttons'>
        <button id='connect-button' type='button' onClick={connectButton}>
          Connect
        </button>
        <button
          id='start-button'
          type='button'
          onClick={() =>
            startButton(
              "Sono Re Desiderio, pronto ad aiutarvi con la mia saggezza e conoscenza, così come ho guidato il mio popolo, i Longobardi, con determinazione."
            )
          }
        >
          Start
        </button>
        <button id='destroy-button' type='button' onClick={destroyButton}>
          Destroy
        </button>
      </div> */}
    </div>
     
  );
};

export default VideoAssistente;
