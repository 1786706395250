import React, { useState, useContext, useEffect } from "react";
import { StateContext } from "../StateContainer";
import axios from "axios";
import Cookies from "js-cookie";
import PoweredBy from "../../img/logoChat.png";
import MailSendedGif from "../../img/mail-sended.gif";
import EmailSendedFunctions from "./EmailSendedFunctions";

const FormGetInfoUser = ({
  progetto,
  conversationId,
  setConversationId,
  handleSendMessage,
  message,
  setMessage,
}) => {
  const {
    showFormRequestContacts,
    setShowFormRequestContacts,
    showFormContactFollowChat,
    leadCode,
    setLeadCode,
  } = useContext(StateContext);

  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    // Ritarda di un breve istante l'attivazione dell'effetto fade-in
    const timer = setTimeout(() => {
      setFadeIn(true);
    }, 100); // puoi regolare il ritardo se necessario

    return () => clearTimeout(timer); // Pulisce il timer quando il componente viene smontato
  }, []);

  const [collectLeadName, setCollectLeadName] = useState("");
  const [collectLeadEmail, setCollectLeadEmail] = useState("");
  const [collectLeadCompany, setCollectLeadCompany] = useState("");
  const [collectLeadJobTitle, setCollectLeadJobTitle] = useState("");
  const [collectLeadPhone, setCollectLeadPhone] = useState("");

  // Animazione dell'email che viene spedita
  const [emailSended, setEmailSended] = useState(false);

  // Animazione dell'email che viene spedita
  const [emailReceived, setEmailRecived] = useState(false);

  // Gestione dell'errore in caso la email non viene spedita
  const [emailError, setEmailError] = useState(false);

  if (!progetto.lead) {
    return null; // Se progetto.lead è undefined o null, non renderizzare nulla
  }

  const CloseFormRequestContacts = (e) => {
    e.preventDefault();
    Cookies.set("showFormRequestContacts-" + progetto.code, false, {
      expires: 182,
      sameSite: "none",
      secure: "true",
    });

    setShowFormRequestContacts(false);
    if (
      progetto.lead.collectLeadWhen === "first-response" ||
      progetto.lead.collectLeadWhen === "follow-conversation"
    ) {
      handleSendMessage(message);
    }
  };

  const leadKeys = [
    {
      id: 1,
      text: "Nome",
      name: "nome_user",
      type: "text",
      name: "name",
      placeholder: "Nome",
      value: collectLeadName,
      functionChange: setCollectLeadName,
      show: progetto.lead.collectLeadName,
    },
    {
      id: 2,
      text: "Email",
      name: "email_user",
      type: "email",
      name: "email",
      placeholder: "Email",
      value: collectLeadEmail,
      functionChange: setCollectLeadEmail,
      show: progetto.lead.collectLeadEmail,
    },
    {
      id: 3,
      text: "Professione",
      name: "work_user",
      type: "text",
      name: "work",
      placeholder: "Professione",
      value: collectLeadJobTitle,
      functionChange: setCollectLeadJobTitle,
      show: progetto.lead.collectLeadJobTitle,
    },
    {
      id: 4,
      text: "Azienda",
      name: "company_user",
      type: "text",
      name: "company",
      placeholder: "Azienda",
      value: collectLeadCompany,
      functionChange: setCollectLeadCompany,
      show: progetto.lead.collectLeadCompany,
    },
    {
      id: 5,
      text: "Numero di Telefono",
      name: "nphone_user",
      type: "tel",
      name: "nphone",
      placeholder: "Numero di Telefono",
      value: collectLeadPhone,
      functionChange: setCollectLeadPhone,
      show: progetto.lead.collectLeadPhone,
    },
  ];
  let formData = {}; // Creiamo un oggetto per raccogliere i dati

  const InviaDatiFormInformazioni = (e) => {
    e.preventDefault();
    setEmailRecived(false);

    const overlay = document.getElementById("overlay");
    const followConversation = document.getElementById("follow-conversation");
    const email_received = document.getElementById("email_received");
    const messageForm = document.getElementById("message-form");


    leadKeys.forEach((lead) => {
      if (lead.show) {
        formData[lead.name] = document.getElementById(lead.name).value;
      }
    });

    let lengthLeadsProject = Object.keys(progetto.lead).filter((key) => {
      const value = progetto.lead[key];
      return (
        value !== null &&
        value !== false &&
        value !== undefined &&
        key !== "collectLeadWhen" &&
        key !== "collectLeadMessage" &&
        key !== "allowSkipContact"
      );
    }).length;

    let lengthFormData = Object.keys(formData).filter((key) => {
      const value = formData[key];
      return (
        value !== null && value !== false && value !== undefined && value !== ""
      );
    }).length;

    let inviaLeadsButton = document.getElementById("inviaLeadsButton");

    if (lengthFormData !== lengthLeadsProject) {
      inviaLeadsButton.classList.add("bounce-animation");

      // Rimuovi la classe dopo un certo periodo di tempo (ad esempio, 1 secondo)
      setTimeout(() => {
        inviaLeadsButton.classList.remove("bounce-animation");
      }, 750);

      return;
    }

    setEmailSended(true);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_PYTHON_ENGINE}/save-info-user`,
      headers: {
        // Correct the typo here from "header" to "headers"
        "Accept": "application/json",
      },
      data: {
        projectCode: progetto.code,
        conversationCode: "",
        name: formData.name,
        email: formData.email,
        company: formData.company,
        jobTitle: formData.work,
        phone: formData.nphone,
      },
    })
      .then((response) => {
        setLeadCode(response.data.response.leadCode);
        setTimeout(() => {
          setEmailSended(false);

          Cookies.set("showFormRequestContacts-" + progetto.code, false, {
            expires: 182,
            sameSite: "none",
            secure: "true",
          });
          formData = {};
          leadKeys.forEach((lead) => {
            lead.functionChange("");
          });

          setEmailRecived(true);
          setTimeout(() => {
            if (overlay) {
              overlay.classList.add("hide-form-user-mail");
            }
            if (followConversation) {
              followConversation.classList.add("hide-form-user-mail");
            }

            setTimeout(() => {
              setShowFormRequestContacts(false);
            }, 1000);

            setEmailRecived(false);
            if (progetto.lead.collectLeadWhen === "first-response") {
              handleSendMessage(message, response.data.response.leadCode);
            }
          }, 2000);
        }, 900);
      })
      .catch((error) => {
        setEmailSended(false);
        setEmailRecived(false);
        setEmailError(true);
        setTimeout(() => {
          if (overlay) {
            overlay.classList.add("hide-form-user-mail");
          }

          if (followConversation) {
            followConversation.classList.add("hide-form-user-mail");
          }
          setEmailError(true);
          if (progetto.lead.collectLeadWhen === "first-response") {
            handleSendMessage(message);
          }
        }, 2000);
      });
  };

  return (
    <>
      {progetto &&
      progetto.lead &&
      progetto.lead.collectLeadWhen === "follow-conversation" ? (
        <div
          id='follow-conversation'
          className={`card d-flex flex-column align-items-center h-auto p-3 fade-in ${fadeIn ? 'show' : ''} ${
        progetto.collectCustomerEmail && showFormRequestContacts && showFormContactFollowChat  ? '' : 'd-none'
      }`}
          style={{ overflow: "hidden", maxWidth: "400px" }}
        >
          <EmailSendedFunctions
            emailSended={emailSended}
            emailReceived={emailReceived}
            emailError={emailError}
            progetto={progetto}
          ></EmailSendedFunctions>
          <div className='card-body w-100 h-auto border-0 p-0'>
            <form
              className={` w-100 ${
                emailSended || emailReceived || emailError ? "d-none" : ""
              }`}
              onSubmit={(e) => InviaDatiFormInformazioni(e)}
              id='form-mail-user'
            >
              <p className='text-black fw-bold' id='message-form'>
                {progetto.lead.collectLeadMessage}
              </p>
              {leadKeys.map((lead) => (
                <React.Fragment key={lead.id}>
                  {lead.show ? (
                    <div className='form-group mt-2' key={lead.id}>
                      <input
                        type={lead.type}
                        className='form-control font-size-14'
                        id={lead.name}
                        placeholder={lead.placeholder}
                        name={lead.name}
                        // required
                        value={lead.value}
                        onChange={(e) => lead.functionChange(e.target.value)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              ))}
              <div className='d-flex align-items-center mt-3 justify-content-between '>
                <button
                  className='btn text-white font-size-14'
                  type='submit'
                  id='inviaLeadsButton'
                  style={{ background: `${progetto.primaryColor}` }}
                >
                  Invia Dati
                </button>
                {progetto.lead.allowSkipContact ? (
                  <button
                    id='skipFormButton'
                    className='btn bg-transparent button-close-contact-form font-size-14'
                    onClick={(e) => CloseFormRequestContacts(e)}
                  >
                    Salta
                  </button>
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div
          id='overlay'
          className={`d-grid align-items-center ${
            progetto.collectCustomerEmail && showFormRequestContacts
              ? ""
              : "d-none"
          }`}
        >
          <EmailSendedFunctions
            emailSended={emailSended}
            emailReceived={emailReceived}
            emailError={emailError}
            progetto={progetto}
          ></EmailSendedFunctions>
          <form
            className={`form-get-email w-100 ${
              emailSended || emailReceived || emailError ? "d-none" : ""
            }`}
            onSubmit={(e) => InviaDatiFormInformazioni(e)}
            id='form-mail-user'
          >
            <p className='text-black fw-bold' id='message-form'>
              {progetto.lead.collectLeadMessage}
            </p>
            {leadKeys.map((lead) => (
              <React.Fragment key={lead.id}>
                {lead.show ? (
                  <div className='form-group mt-3' key={lead.id}>
                    <label
                      htmlFor={lead.name}
                      className='text-black mb-2 fw-bold '
                      value={lead.text}
                    >
                      {lead.text}
                    </label>
                    <input
                      type={lead.type}
                      className='form-control'
                      id={lead.name}
                      placeholder={lead.placeholder}
                      name={lead.name}
                      // required
                      value={lead.value}
                      onChange={(e) => lead.functionChange(e.target.value)}
                    />
                  </div>
                ) : (
                  ""
                )}
              </React.Fragment>
            ))}
            <div className='d-flex align-items-center mt-5 justify-content-between '>
              <button
                className='btn text-white'
                type='submit'
                id='inviaLeadsButton'
                style={{ background: `${progetto.primaryColor}` }}
              >
                Inizia Chat
              </button>
              {progetto.lead.allowSkipContact ? (
                <button
                  id='skipFormButton'
                  className='btn bg-transparent button-close-contact-form'
                  onClick={(e) => CloseFormRequestContacts(e)}
                >
                  Salta
                </button>
              ) : (
                ""
              )}
            </div>
          </form>
          <div className='w-100 h-100 d-flex align-items-end pb-5 justify-content-between my-1 px-4 banner-tailor d-md-none'>
            <div className='my-1'>
              <a
                href='https://tailorgpt.ai/'
                target='_blank'
                className='text-decoration-none'
              >
                <img
                  style={{
                    width: "auto",
                    height: "1.3vw",
                    minHeight: "15px",
                  }}
                  src={`${PoweredBy}`}
                  alt=''
                  className='img-fulgeas-chat'
                />
              </a>
            </div>
            {progetto ? (
              progetto.privacy ? (
                <div className='my-2 d-flex align-items-center'>
                  <a
                    className='font-size-11'
                    style={{ color: "#535353" }}
                    href={`${progetto.privacy}`}
                    target='_blank'
                  >
                    Privacy
                  </a>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default FormGetInfoUser;
