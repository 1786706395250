import React, { useEffect } from 'react';
import Page404 from './Page404';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom';
import ProvaChatbot from './ProvaChatbot';
import DemoSpeech from './DemoSpeechToText';
import DemoTalkingAssistant from './DemoTalkingAssistant/DemoTalkingAssistant';
import VideoAssistente from './DemoTalkingAssistant/VideoAssistente';
import SqlAgent from './SqlAgent';
import KnotsChatbot from './KnotsChatbot';

const RoutesSite = (url) => {
  return (
    <Routes>
      <Route path="/" element={<ProvaChatbot />} />
      <Route path="/knots-chatbot" element={<ProvaChatbot />} />
      <Route path="/sql-agent" element={<ProvaChatbot />} />
      <Route path="/demo-speech" element={<DemoSpeech />} />
      <Route path="/brescia-musei" element={<DemoTalkingAssistant />} />
      <Route path="/video-assistente" element={<VideoAssistente />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default RoutesSite;
