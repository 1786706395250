import React from 'react';
import Moment from 'react-moment';

const MessaggioUtente = ({ row }) => {
  return (
    <li className=" right message-utente">
      <div className="conversation-list pb-4">
        <div className="ctext-wrap m-0">
          <div
            className="ctext-wrap-content m-0 text-white"
            style={{
              background: `rgba(83, 83, 83, 1)`,
              maxWidth: '300px',
              hyphens: 'auto',
            }}
          >
            <p className="mb-0 text-white">{row.userMessage.message}</p>
          </div>
        </div>
        <span className="d-inline-block font-size-12 text-muted mt-1">
          <Moment format="HH:mm">{row.userMessage.date}</Moment>
        </span>
      </div>
    </li>
  );
};

export default MessaggioUtente;
