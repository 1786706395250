import React, { useState, useEffect, useRef, useContext } from 'react';
import { IoMdSend as Invia } from 'react-icons/io';
import axios from 'axios';
import './App.css';
import { FaMicrophone, FaDotCircle } from 'react-icons/fa';
import NuovaChat from './components/NuovaChat';
import { StateContext } from './components/StateContainer';
import PoweredBy from './img/logoChat.png';
import FormGetInfoUser from './components/FormGetInfoUser/FormGetInfoUser';
import Cookies from 'js-cookie';
import { FaStop as Stop } from 'react-icons/fa';
import { BsThreeDotsVertical as DotsSettings } from 'react-icons/bs';
import { BsTrash3 as Cestino } from 'react-icons/bs';
import useSpeechRecognition from './components/hooks/UseSpeechRecognition';

const ProvaChatbot = ({ content }) => {
  let url = new URL(window.location.href);
  let urlProjectCode = url.searchParams.get('projectCode');

  const firstMessageDate = [{ date: new Date() }];
  const [error, setError] = useState('');
  const [totalChat, setTotalChat] = useState([]);
  const [disableUserMessageChat, setDisableUserMessageChat] = useState(false);
  const [projectCode, setProjectCode] = useState('');
  const [conversationId, setConversationId] = useState(
    Cookies.get('conversationId-' + urlProjectCode)
  );
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const [caricamento, setCaricamento] = useState(false);
  const [reloadIframe, setRealoadIframe] = useState(false);
  const [ip, setIP] = useState('');

  const userLastMessageRef = useRef('');

  const [urlSite, setUrlSite] = useState(window.location.href);

  window.addEventListener('message', (event) => {
    if (event.data.action === 'elimina_cookie') {
      const cookieName = event.data.cookieName;
      // Rimuovi il cookie
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

      const cardBody = cardBodyRef.current;
    }

    if (event.data.action === 'cookieWebSite') {
      setUrlSite(event.data.currentUrl);
    }
    if (event.data.action === 'reload') {
      setRealoadIframe(event.data.action);
    }
    if (event.data.action === 'reloadIframe') {
      window.location.reload();
    }
  });

  useEffect(() => {
    setProjectCode(url.searchParams.get('projectCode'));
  }, []);

  useEffect(() => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/public/conversation/detail`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      data: {
        projectCode: urlProjectCode,
        conversationCode: Cookies.get('conversationId-' + urlProjectCode),
      },
    })
      .then((res) => {
        let conversation = [];
        if (res.data.response) {
          res.data.response.length !== 0 &&
            res.data.response.forEach((res) => {
              conversation.push({
                userMessage: {
                  message: res.userText,
                  date: res.date.date,
                },
                botMessage: {
                  text: res.botText.trim(),
                  sources: '',
                  date: res.date.date,
                  split: true,
                  faq_answer: false,
                },
              });
            });
        }
        setTotalChat(conversation);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const {
    message,
    setMessage,
    dettagliProgetto,
    setDettagliProgetto,
    showFormRequestContacts,
    setShowFormRequestContacts,
    leadCode,
    setStopAgent,
    stopAgent,
    setLeadCode,
    typeWriteText,
    setShowFormContactFollowChat,
    currentIndexTypeWrite,
    loading,
    setLoading,
    isTypingMachine,
    botMessageSent,
    setBotMessageSent,
    typedTextScroll,
    currentLine,
    cardBodyRef,
    conversationStarted,
    showLoading,
    setShowLoading,
    setConversationStarted,
  } = useContext(StateContext);

  const {
    text,
    startListening,
    stopListening,
    isListening,
    hasRecognitionSupport,
  } = useSpeechRecognition();

  const getData = async () => {
    const res = await axios.get('https://api.ipify.org/?format=json');
    setIP(res.data.ip);
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  useEffect(() => {
    if (!Cookies.get('conversationId-' + urlProjectCode)) {
      const timestamp = Date.now();
      const random = Math.floor(Math.random() * 100000);
      Cookies.set(
        'conversationId-' + urlProjectCode,
        `${timestamp}-${random}`,
        {
          path: '',
          sameSite: 'none',
          secure: 'true',
        }
      );
      setConversationId(`${timestamp}-${random}`);
    } else {
      // Niente
    }
  }, []);

  useEffect(() => {
    setCaricamento(true);
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/public/project/detail`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      data: {
        projectCode: projectCode,
      },
    })
      .then((response) => {
        setError('');

        setRealoadIframe(false);
        setCaricamento(false);
        const cardBody = cardBodyRef.current;

        cardBody.scrollTop = cardBody.scrollHeight - cardBody.clientHeight;

        setDettagliProgetto(response.data.response);

        let tipo_progetto = response.data.response.type;
        if (!Cookies.get('type-project-' + projectCode)) {
          Cookies.set('type-project-' + projectCode, tipo_progetto);
        }

        if (
          (response.data.response.collectCustomerEmail &&
            response.data.response.lead.collectLeadWhen === 'before-chat' &&
            Cookies.get(`showFormRequestContacts-${projectCode}`) !==
              'false') ||
          (response.data.response.collectCustomerEmail &&
            response.data.response.lead.collectLeadWhen === 'before-chat' &&
            Cookies.get(`showFormRequestContacts-${projectCode}`) === undefined)
        ) {
          setShowFormRequestContacts(true);
        }
      })
      .catch((err) => setError(err));
  }, [projectCode, reloadIframe]);

  const handleKeyDown = (event) => {
    if (!conversationStarted) {
      if (event.key === 'Enter') {
        ControlsBeforeSendingMessage(event.target.value);
      }
    }
  };

  const ControlsBeforeSendingMessage = (messaggio, widget_clicked = false) => {
    setShowFormContactFollowChat(false);
    var faqActions = document.querySelectorAll('.faq-actions');
    faqActions.forEach(function (element, index) {
      element.classList.remove('animationDone');
      element.classList.add('d-none');
    });
    setMessage(messaggio);
    if (
      (totalChat.length === 0 &&
        dettagliProgetto &&
        dettagliProgetto.lead &&
        dettagliProgetto.collectCustomerEmail &&
        dettagliProgetto.lead.collectLeadWhen &&
        dettagliProgetto.lead.collectLeadWhen === 'first-response' &&
        Cookies.get(`showFormRequestContacts-${projectCode}`) !== 'false') ||
      (totalChat.length === 0 &&
        dettagliProgetto &&
        dettagliProgetto.lead &&
        dettagliProgetto.collectCustomerEmail &&
        dettagliProgetto.lead.collectLeadWhen &&
        dettagliProgetto.lead.collectLeadWhen === 'first-response' &&
        Cookies.get(`showFormRequestContacts-${projectCode}`) === undefined)
    ) {
      setShowFormRequestContacts(true);
    } else {
      handleSendMessage(messaggio, leadCode, widget_clicked);
    }
  };

  const handleSendMessage = (messaggio, codeLead, widget_clicked = false) => {
    setDisableUserMessageChat(false);
    var faqActions = document.querySelectorAll('.faq-actions');
    console.log(faqActions);
    faqActions.forEach(function (element) {
      element.classList.remove('animationDoneActionsFaq');
      element.classList.add('d-none');
    });
    setStopAgent(false);
    setConversationStarted(true);
    setBotMessageSent(true);
    setIsUserScrolling(false);
    setLoading(true);
    let chatStorico = totalChat.slice(); // Copia l'array originale
    let massimoStoricoChat = dettagliProgetto.messageHistoryLimit || 0;

    if (massimoStoricoChat === 0) {
      // Caso speciale: nessun messaggio deve rimanere
      chatStorico = [];
    } else if (chatStorico.length > massimoStoricoChat) {
      // Rimuovi i messaggi in eccesso
      const elementiInEccesso = chatStorico.length - massimoStoricoChat;
      chatStorico.splice(0, elementiInEccesso);
    }
    const userMessage = message.trim() || messaggio || '';
    if (userMessage === '') return;

    if (message) userLastMessageRef.current = userMessage;
    setMessage('');

    const conversation = {
      userMessage: {
        message: userMessage,
        date: new Date(),
      },
      botMessage: {
        text: ``,
        sources: '',
        interactionCode: '',
        date: new Date(),
        split: false,
        faq_answer: false,
        faq_actions: [],
        show_thumbs: true,
        dataGraphic: [],
        optionsGraphic: [],
        faq_actions: [],
        faq_answer: false,
        sql_graphic: false,
        sql_table: false,
        sql_table_content: '',
      },
    };
    setTotalChat((prevTotal) => [...prevTotal, conversation]);

    let apiURL;
    let tipo_progetto = Cookies.get('type-project-' + projectCode);

    if (tipo_progetto === 'conversational') {
      apiURL = `${process.env.REACT_APP_PYTHON_ENGINE}/knots-chatbot`;
    } else if (tipo_progetto === 'mysql-agent') {
      apiURL = `${process.env.REACT_APP_PYTHON_ENGINE}/mysql-chatbot`;
    } else {
      apiURL = `${process.env.REACT_APP_PYTHON_ENGINE}/chatbot`;
    }

    const requestOptions = {
      method: 'POST', // Puoi impostare il metodo come 'GET' o 'POST' in base alle tue esigenze
      headers: {
        'Content-Type': 'application/json',
        // "Cache-Control": "no-cache",
      },
      body: JSON.stringify({
        message: userMessage,
        sessionId: conversationId,
        projectCode: dettagliProgetto.code,
        istruzioniChatbot: dettagliProgetto.basePrompt,
        temperature: dettagliProgetto.temperature,
        analysisDepth: dettagliProgetto.analysisDepth,
        messaggi: chatStorico,
        collectCustomerEmail: dettagliProgetto.collectCustomerEmail,
        numberOfInteraction: chatStorico.length + 1,
        leadCode: leadCode || codeLead,
        widgetClicked: widget_clicked,
        ip: ip,
        link: urlSite,
        currentNode: Cookies.get('currentNode-' + urlProjectCode)
          ? Cookies.get('currentNode-' + urlProjectCode)
          : 1,
        nextNode: Cookies.get('nextNodeProject-' + urlProjectCode)
          ? Cookies.get('nextNodeProject-' + urlProjectCode)
          : 1,
        variables: Cookies.get('variables-' + urlProjectCode)
          ? Cookies.get('variables-' + urlProjectCode)
          : '[]',
        external_service_knoledge: Cookies.get(
          'external_service_knoledge-' + urlProjectCode
        )
          ? Cookies.get('external_service_knoledge-' + urlProjectCode)
          : '[]',
      }),
    };

    fetch(apiURL, requestOptions)
      .then((response) => {
        setBotMessageSent(false);
        setLoading(false);
        if (!response.ok) {
          throw new Error('Errore durante la richiesta.');
        }

        conversation.botMessage.text = '';

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        const readChunk = () => {
          let chunk = '';
          return reader.read().then(({ done, value }) => {
            if (done) {
              return;
            }

            let response = decoder
              .decode(value, { stream: true })
              .replace(/}{/g, '},{');

            let valueOfResponse = JSON.parse(`[${response}]`);

            if (valueOfResponse[0].faq_answer) {
              valueOfResponse.forEach((value) => {
                chunk += value.answer;
              });
              conversation.botMessage.faq_answer = true;
              if (valueOfResponse[0].actions) {
                conversation.botMessage.faq_actions.push(
                  valueOfResponse[0].actions
                );
              }
              if (valueOfResponse[0].interactionCode) {
                let interactionCode = valueOfResponse[0].interactionCode;
                console.log(interactionCode);
                conversation.botMessage.interactionCode +=
                  valueOfResponse[0].interactionCode;
              }
              if ('show_thumbs' in valueOfResponse[0]) {
                let show_thumbs = valueOfResponse[0].show_thumbs;
                conversation.botMessage.show_thumbs += show_thumbs;
              }
              if (valueOfResponse[0].currentNodeProject) {
                let currentNode = valueOfResponse[0].currentNodeProject;
                let nome = 'currentNode-' + urlProjectCode;
                Cookies.set(nome, currentNode, {
                  path: '',
                  sameSite: 'none',
                  secure: 'true',
                });
              }
              if (valueOfResponse[0].nextNodeProject) {
                let nextNode = valueOfResponse[0].nextNodeProject;
                let nome = 'nextNodeProject-' + urlProjectCode;
                Cookies.set(nome, nextNode, {
                  path: '',
                  sameSite: 'none',
                  secure: 'true',
                });
              }
              if (valueOfResponse[0].disable_user_text) {
                let disable_user_text = valueOfResponse[0].disable_user_text;
                setDisableUserMessageChat(disable_user_text);
              }
              if (valueOfResponse[0].var) {
                let variables = valueOfResponse[0].var;
                if (variables !== '[]') {
                  let nome = 'variables-' + urlProjectCode;
                  Cookies.set(nome, JSON.stringify(variables), {
                    path: '',
                    sameSite: 'none',
                    secure: 'true',
                  });
                }
              }
              if (valueOfResponse[0].external_service_knoledge) {
                let external_service_knoledge =
                  valueOfResponse[0].external_service_knoledge;
                if (external_service_knoledge !== '[]') {
                  let nome = 'external_service_knoledge-' + urlProjectCode;
                  Cookies.set(nome, JSON.stringify(external_service_knoledge), {
                    path: '',
                    sameSite: 'none',
                    secure: 'true',
                  });
                }
              }
            } else if (
              !valueOfResponse[0].faq_answer &&
              valueOfResponse[0].sql_graphic
            ) {
              conversation.botMessage.dataGraphic = valueOfResponse[0].data;
              conversation.botMessage.optionsGraphic =
                valueOfResponse[0].options;
              conversation.botMessage.faq_answer = false;
              conversation.botMessage.sql_graphic = true;
              conversation.botMessage.link_gestionale =
                valueOfResponse[1].link_gestionale;
              conversation.sql_graphic = true;
              // conversation.faq_answer = false;
            } else if (
              !valueOfResponse[0].faq_answer &&
              !valueOfResponse[0].sql_graphic &&
              valueOfResponse[0].sql_table
            ) {
              conversation.botMessage.faq_answer = false;
              conversation.botMessage.sql_graphic = false;
              conversation.botMessage.sql_table = true;
              conversation.botMessage.sql_table_content =
                valueOfResponse[0].answer;
              conversation.botMessage.link_gestionale =
                valueOfResponse[1].link_gestionale;
              // conversation.faq_answer = false;
            } else {
              valueOfResponse.forEach((value) => {
                chunk += value.answer;
              });
            }

            // Controlla se il chunk contiene [sources] e [/source]
            conversation.botMessage.faq_answer += false;
            const startTag = '[sources]';
            const endTag = '[/sources]';
            const startIndex = chunk.indexOf(startTag);
            const endIndex = chunk.indexOf(endTag);

            conversation.botMessage.split += true;

            if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
              // Il chunk contiene [sources] e [/source], aggiungi il testo tra di essi a conversation.botMessage.sources
              const contentStartIndex = startIndex + startTag.length;
              const contentEndIndex = endIndex;
              const sourcesText = chunk.substring(
                contentStartIndex,
                contentEndIndex
              );
              conversation.botMessage.sources += sourcesText;
            } else {
              // Il chunk non contiene [sources] e [/source], aggiungi il chunk intero a conversation.botMessage.text
              conversation.botMessage.text += chunk;
            }
            setTotalChat((prevTotal) => [...prevTotal]); // Aggiorna lo stato di totalChat

            setTimeout(() => {
              readChunk();
            }, 100); // 2000 millisecondi (2 secondi)
          });
        };

        return readChunk();
      })
      .then(() => {
        setLeadCode('');
        if (
          (dettagliProgetto &&
            dettagliProgetto.lead &&
            dettagliProgetto.collectCustomerEmail &&
            dettagliProgetto.lead.collectLeadWhen &&
            dettagliProgetto.lead.collectLeadWhen === 'follow-conversation' &&
            totalChat.length >= 0 &&
            Cookies.get(`showFormRequestContacts-${projectCode}`) !==
              'false') ||
          (dettagliProgetto &&
            dettagliProgetto.lead &&
            dettagliProgetto.collectCustomerEmail &&
            dettagliProgetto.lead.collectLeadWhen &&
            dettagliProgetto.lead.collectLeadWhen === 'follow-conversation' &&
            totalChat.length >= 0 &&
            Cookies.get(`showFormRequestContacts-${projectCode}`) === undefined)
        ) {
          setShowFormRequestContacts(true);
        }
      })
      .catch((error) => {
        console.error('Errore durante la richiesta:', error);
        conversation.botMessage.text =
          'Si è verificato un errore durante la richiesta.';
        setTotalChat((prevTotal) => [...prevTotal]);
      });
  };

  if (dettagliProgetto) {
    document.title = dettagliProgetto.title || 'Ai Assistant';
  }

  useEffect(() => {
    const cardBody = cardBodyRef.current;
    let scrollTimeout;

    const handleScroll = () => {
      clearTimeout(scrollTimeout);

      const isAtBottom =
        cardBody.scrollTop === cardBody.scrollHeight - cardBody.clientHeight;

      if (!isAtBottom) {
        setIsUserScrolling(true);

        scrollTimeout = setTimeout(() => {
          setIsUserScrolling(false);
        }, 100); // Maggiore tolleranza rispetto a prima
      } else {
        setIsUserScrolling(false);
      }
    };

    cardBody.addEventListener('scroll', handleScroll);

    return () => {
      cardBody.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, []);

  useEffect(() => {
    const cardBody = cardBodyRef.current;

    if (!isUserScrolling) {
      // Scrolla automaticamente solo se l'utente non sta interagendo
      const isCloseToBottom =
        cardBody.scrollTop >=
        cardBody.scrollHeight - cardBody.clientHeight - 50;

      if (isCloseToBottom) {
        cardBody.scrollTop = cardBody.scrollHeight - cardBody.clientHeight;
      }
    }
  }, [
    totalChat,
    typedTextScroll, // Aggiunto per monitorare il testo che viene digitato
    caricamento,
    conversationId,
    isTypingMachine,
    botMessageSent,
    showLoading,
  ]);

  useEffect(() => {
    if (botMessageSent) {
      const cardBody = cardBodyRef.current;
      setTimeout(() => {
        cardBody.scrollTop = cardBody.scrollHeight - cardBody.clientHeight;
      }, 100); // Ritardo per sincronizzare il rendering
    }
  }, [botMessageSent]);

  var hexSecondaryColor = null;
  var secondaryRed = null;
  var secondaryGreen = null;
  var secondaryBlue = null;

  var secondaryRGBA = null;

  let alpha = 0.1; // Opacità
  let secAlpha = 0.9; // Opacità
  let red = null;
  let green = null;
  let blue = null;
  let redHex = null;
  let greenHex = null;
  let blueHex = null;
  let hexColorPerSVG = null;

  if (dettagliProgetto) {
    hexSecondaryColor = dettagliProgetto.secondaryColor;

    if (hexSecondaryColor) {
      // Estrai i componenti di rosso, verde, blu dall'hex
      secondaryRed = parseInt(hexSecondaryColor.slice(1, 3), 16);
      secondaryGreen = parseInt(hexSecondaryColor.slice(3, 5), 16);
      secondaryBlue = parseInt(hexSecondaryColor.slice(5, 7), 16);

      secondaryRGBA = `rgba(${secondaryRed}, ${secondaryGreen}, ${secondaryBlue}, ${alpha})`;

      red = Math.round(secondaryRed + (255 - secondaryRed) * secAlpha);
      green = Math.round(secondaryGreen + (255 - secondaryGreen) * secAlpha);
      blue = Math.round(secondaryBlue + (255 - secondaryBlue) * secAlpha);

      redHex = red.toString(16).padStart(2, '0');
      greenHex = green.toString(16).padStart(2, '0');
      blueHex = blue.toString(16).padStart(2, '0');

      hexColorPerSVG = `#${redHex}${greenHex}${blueHex}`;
    }
  }
  const [hoveredButton, setHoveredButton] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredButton(index);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const StopButtonClick = () => {
    setStopAgent(true);
    setConversationStarted(false);
  };

  const CancellaCronologiaChat = () => {
    Cookies.remove('conversationId-' + projectCode);
    Cookies.remove('currentNode-' + projectCode);
    Cookies.remove('nextNodeProject-' + projectCode);
    Cookies.remove('variables-' + projectCode);
    Cookies.remove('external_service_knoledge-' + projectCode);
    const closeButtonModal = document.getElementById(
      'close-button-delete-cookies'
    );
    if (closeButtonModal) {
      closeButtonModal.click();
    }
    const closeButtonOffcanvas = document.getElementById(
      'close-button-cookies-offcanvas'
    );
    if (closeButtonOffcanvas) {
      closeButtonOffcanvas.click();
    }
    document.location.reload();
  };

  return (
    <div className="App">
      <div id="layout-wrapper">
        <div className="main-content m-0 p-0 h-100">
          <div className="page-content p-0">
            {dettagliProgetto &&
            dettagliProgetto.lead &&
            dettagliProgetto.collectCustomerEmail &&
            dettagliProgetto.lead.collectLeadWhen !== 'follow-conversation' ? (
              <FormGetInfoUser
                handleSendMessage={handleSendMessage}
                message={message}
                progetto={dettagliProgetto}
                conversationId={conversationId}
                setConversationId={setConversationId}
                setMessage={setMessage}
              ></FormGetInfoUser>
            ) : (
              ''
            )}
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 px-0 position-fixed fixed-top">
                  <div className="w-100 user-chat mt-sm-0">
                    <div
                      className="card"
                      style={{
                        border: 0,
                      }}
                    >
                      <div
                        ref={cardBodyRef}
                        className="card-body"
                        id="cardBodyChat"
                        dir="ltr"
                        style={{
                          overflowX: 'hidden',
                          overflowY: 'scroll',
                          border: 0,
                          position: 'relative',
                        }}
                      >
                        {caricamento ? (
                          <div className="form-outline mt-5">
                            <div className="loading-dots">
                              <div className="loading-dots--dot"></div>
                              <div className="loading-dots--dot"></div>
                              <div className="loading-dots--dot"></div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="chat-conversation py-3">
                              <ul
                                className="list-unstyled mb-0 chat-conversation-message"
                                data-simplebar
                                style={{ fontSize: '0.875rem' }}
                                id="ulList"
                              >
                                {dettagliProgetto &&
                                dettagliProgetto.creditsLeft === 0 ? (
                                  <li>
                                    <div className="conversation-list">
                                      <div
                                        className="alert alert-danger"
                                        role="alert"
                                      >
                                        Hai terminato i crediti a tua
                                        disposizione. Per continuare a
                                        utilizzare la chatbot, rinnova il tuo
                                        piano mensile
                                      </div>
                                    </div>
                                  </li>
                                ) : (
                                  <>
                                    <NuovaChat
                                      progetto={dettagliProgetto}
                                      chat={totalChat}
                                      firstMessageDate={firstMessageDate}
                                      caricamento={caricamento}
                                      totalChat={totalChat}
                                      ControlsBeforeSendingMessage={
                                        ControlsBeforeSendingMessage
                                      }
                                      setIsUserScrolling={setIsUserScrolling}
                                      setLoading={setLoading}
                                      message={message}
                                      userLastMessageRef={userLastMessageRef}
                                      setMessage={setMessage}
                                      setTotalChat={setTotalChat}
                                      conversationId={conversationId}
                                      setLeadCode={setLeadCode}
                                    />
                                  </>
                                )}

                                {dettagliProgetto &&
                                dettagliProgetto.lead &&
                                dettagliProgetto.collectCustomerEmail &&
                                dettagliProgetto.lead.collectLeadWhen &&
                                dettagliProgetto.lead.collectLeadWhen ===
                                  'follow-conversation' ? (
                                  <>
                                    <FormGetInfoUser
                                      handleSendMessage={handleSendMessage}
                                      message={message}
                                      progetto={dettagliProgetto}
                                      conversationId={conversationId}
                                      setConversationId={setConversationId}
                                      setMessage={setMessage}
                                    ></FormGetInfoUser>
                                    <li style={{ height: '70px' }}></li>
                                  </>
                                ) : (
                                  ''
                                )}
                                <li style={{ height: '60px' }}></li>
                              </ul>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="divider"></div>

                      <div
                        className="position-absolute w-100 text-center p-3"
                        style={{ bottom: '0' }}
                      >
                        {dettagliProgetto && dettagliProgetto.predefinedQueries
                          ? dettagliProgetto.predefinedQueries.map(
                              (query, index) => (
                                <button
                                  key={index} // Assicurati di aggiungere una chiave unica per ogni pulsante
                                  className="btn div-advice-buttons mx-2 font-size-14"
                                  onClick={() =>
                                    ControlsBeforeSendingMessage(query[1])
                                  }
                                  onMouseEnter={() => handleMouseEnter(index)}
                                  onMouseLeave={handleMouseLeave}
                                  style={{
                                    backgroundColor:
                                      hoveredButton === index
                                        ? dettagliProgetto &&
                                          dettagliProgetto.secondaryColor // colore verde
                                        : 'white', // colore bianco
                                  }}
                                >
                                  <p className="m-0">{query[0]}</p>
                                </button>
                              )
                            )
                          : ''}
                      </div>

                      {caricamento ? (
                        ''
                      ) : dettagliProgetto &&
                        dettagliProgetto.creditsLeft === 0 ? (
                        ''
                      ) : (
                        <div
                          className={`py-5 card-footer chat-input-section d-flex flex-fill align-items-start w-100 justify-content-center bg-white footer-chat 
                          position-fixed fixed-bottom`}
                          style={{ zIndex: '999' }}
                        >
                          <div className="footer-row row w-100 d-flex justify-content-center m-0">
                            {/* Bottoni Consiglia Messaggio Al bot */}

                            {/* Fine Bottoni Consiglia Messaggio Al bot */}
                            <div
                              style={{
                                backgroundColor: `${secondaryRGBA}`,
                                height: '50px',
                                borderTop: `${
                                  dettagliProgetto
                                    ? `1px ${dettagliProgetto.secondaryColor} solid`
                                    : ''
                                }`,
                                borderBottom: `${
                                  dettagliProgetto
                                    ? `1px ${dettagliProgetto.secondaryColor} solid`
                                    : ''
                                }`,
                              }}
                              className="w-100 d-flex justify-content-center p-0 align-items-center m-0 px-4"
                            >
                              <div className="w-100 ">
                                <div className="position-relative">
                                  <input
                                    type="text"
                                    className="form-control search-bar w-100 bg-transparent border-0 shadow-none px-0"
                                    style={{ outline: 'none' }}
                                    placeholder={`Invia un messaggio`}
                                    value={message}
                                    onChange={(event) =>
                                      setMessage(event.target.value)
                                    }
                                    onKeyDown={handleKeyDown}
                                    disabled={disableUserMessageChat}
                                  />
                                </div>
                              </div>

                              <div className="col-auto d-flex align-items-center d-none d-md-block">
                                {!conversationStarted ? (
                                  <>
                                    {!isListening ? (
                                      <span
                                        className="m-0 micro-icon d-flex align-items-center justify-content-center"
                                        onClick={startListening}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        <FaMicrophone />
                                      </span>
                                    ) : (
                                      <span
                                        className="m-0 micro-icon d-flex align-items-center justify-content-center text-danger registration"
                                        onClick={stopListening}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        <FaDotCircle />
                                      </span>
                                    )}
                                    <button
                                      type="submit"
                                      className={`btn chat-send w-md waves-effect waves-light d-flex align-items-center justify-content-center border-0 ${
                                        message === '' ? '' : ''
                                      }`}
                                      style={{
                                        backgroundColor: `${
                                          dettagliProgetto
                                            ? dettagliProgetto.secondaryColor
                                            : ''
                                        }`,

                                        color: `${hexColorPerSVG}`,

                                        border: '1px grey solid',
                                      }}
                                      onClick={() =>
                                        ControlsBeforeSendingMessage(message)
                                      }
                                    >
                                      <span className="d-none d-sm-inline-block me-2">
                                        Invia
                                      </span>
                                      <Invia
                                        fill={`${hexColorPerSVG}`}
                                        stroke={`${hexColorPerSVG}`}
                                      />{' '}
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    type="submit"
                                    className={`btn chat-send w-md waves-effect waves-light d-flex align-items-center justify-content-center border-0 ${
                                      message === '' ? '' : ''
                                    }`}
                                    style={{
                                      backgroundColor: `${
                                        dettagliProgetto
                                          ? dettagliProgetto.secondaryColor
                                          : ''
                                      }`,

                                      color: `${hexColorPerSVG}`,

                                      border: '1px grey solid',
                                    }}
                                    onClick={StopButtonClick}
                                  >
                                    <span className="d-none d-sm-inline-block me-2">
                                      Stop
                                    </span>

                                    <Stop
                                      fill={`${hexColorPerSVG}`}
                                      stroke={`${hexColorPerSVG}`}
                                    />
                                  </button>
                                )}
                              </div>

                              <div className="col-auto d-flex align-items-center d-block d-md-none">
                                {!conversationStarted ? (
                                  <>
                                    {!isListening ? (
                                      <span
                                        className="m-0 micro-icon d-flex align-items-center justify-content-center"
                                        onClick={startListening}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        <FaMicrophone />
                                      </span>
                                    ) : (
                                      <span
                                        className="m-0 micro-icon d-flex align-items-center justify-content-center text-danger registration"
                                        onClick={stopListening}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        <FaDotCircle />
                                      </span>
                                    )}
                                    <button
                                      type="submit"
                                      className="btn chat-send waves-effect waves-light d-flex align-items-center justify-content-center border-0 rounded-circle p-0 fs-4 "
                                      style={{
                                        backgroundColor: `${
                                          dettagliProgetto
                                            ? dettagliProgetto.secondaryColor
                                            : ''
                                        }`,
                                        height: '40px',
                                        width: '40px',

                                        color: `${hexColorPerSVG}`,

                                        border: '1px grey solid',
                                      }}
                                      onClick={() =>
                                        ControlsBeforeSendingMessage(message)
                                      }
                                    >
                                      <Invia
                                        fill={`${hexColorPerSVG}`}
                                        stroke={`${hexColorPerSVG}`}
                                      />
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn chat-send waves-effect waves-light d-flex align-items-center justify-content-center border-0 rounded-circle p-0 fs-4 "
                                    style={{
                                      backgroundColor: `${
                                        dettagliProgetto
                                          ? dettagliProgetto.secondaryColor
                                          : ''
                                      }`,
                                      height: '40px',
                                      width: '40px',

                                      color: `${hexColorPerSVG}`,

                                      border: '1px grey solid',
                                    }}
                                    onClick={StopButtonClick}
                                  >
                                    <Stop
                                      fill={`${hexColorPerSVG}`}
                                      stroke={`${hexColorPerSVG}`}
                                    />
                                  </button>
                                )}
                              </div>
                            </div>

                            <div className="w-100 d-flex align-items-start justify-content-between my-2 px-4 mb-4">
                              <div className="d-flex gap-5 align-items-start">
                                {dettagliProgetto &&
                                dettagliProgetto.hideTailorGPTLogo ? (
                                  ''
                                ) : (
                                  <div className="my-1">
                                    <a
                                      href="https://tailorgpt.ai/"
                                      target="_blank"
                                      className="text-decoration-none d-flex"
                                    >
                                      <p
                                        className="font-size-11 m-0"
                                        style={{ color: '#535353' }}
                                      >
                                        Powered By
                                      </p>
                                      <div className="logo-tailor-container d-flex align-items-center gap-2 position-relative overflow-hidden">
                                        <div className="shine"></div>
                                        <img
                                          style={{
                                            width: 'auto',
                                          }}
                                          src={`${PoweredBy}`}
                                          alt="Tailor GPT - your own generative AI"
                                          className="img-fulgeas-chat"
                                        />
                                      </div>
                                    </a>
                                  </div>
                                )}
                                {dettagliProgetto ? (
                                  dettagliProgetto.privacy ? (
                                    <div className="my-1 pb-4 d-flex align-items-center">
                                      <a
                                        className="font-size-11"
                                        style={{ color: '#535353' }}
                                        href={`${dettagliProgetto.privacy}`}
                                        target="_blank"
                                      >
                                        Privacy
                                      </a>
                                    </div>
                                  ) : (
                                    ''
                                  )
                                ) : (
                                  ''
                                )}
                              </div>

                              <div className="my-1 pb-4 d-flex align-items-center">
                                <a
                                  className="font-size-15"
                                  style={{ color: '#535353' }}
                                  type="button"
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#offcanvasWithBothOptions"
                                  aria-controls="offcanvasWithBothOptions"
                                >
                                  <DotsSettings />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* Offcanvas  */}
                    <div
                      className="offcanvas offcanvas-start w-100 p-0 m-0"
                      data-bs-scroll="true"
                      tabIndex="-1"
                      id="offcanvasWithBothOptions"
                      aria-labelledby="offcanvasWithBothOptionsLabel"
                    >
                      <div className="offcanvas-header">
                        <h5
                          className="offcanvas-title"
                          id="offcanvasWithBothOptionsLabel"
                        >
                          Impostazioni Chat
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="offcanvas"
                          id="close-button-cookies-offcanvas"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="offcanvas-body">
                        <button
                          className="text-danger d-flex align-items-center gap-2 bg-transparent"
                          data-bs-toggle="modal"
                          data-bs-target={`#modal-delete-cookies`}
                        >
                          <Cestino /> Cancella cronologia
                        </button>
                      </div>
                    </div>
                    {/* Offcanvas  */}

                    {/* MODALE */}
                    <div
                      className="modal fade overlay"
                      id={`modal-delete-cookies`}
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                      data-bs-backdrop="false"
                      style={{ zIndex: '99999999999999999999999999999999999' }}
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header border-0 py-4">
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              id={`close-button-delete-cookies`}
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            Confermi di voler cancellare l'attuale cronologia
                            della chat?
                          </div>
                          <div className="modal-footer border-0">
                            <button
                              type="button"
                              className="btn btn-danger"
                              data-bs-dismiss="modal"
                            >
                              Annulla
                            </button>
                            <button
                              type="button"
                              className="btn btn-dark text-white"
                              id="creaProgettoButton"
                              onClick={CancellaCronologiaChat}
                            >
                              Confermo
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* FINE MODALE  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProvaChatbot;
